import config from "@/config"
import http from "@/utils/request"

export default {
	add: {
		url: `${config.API_URL}/entity/`,
		name: "添加单位",
		post: async function(data){
			
			return await http.post(this.url, data);
		}
	},
    delete: {
		url: `${config.API_URL}/entity/`,
		name: "删除单位",
		delete: async function(id,data){
			return await http.delete(this.url+id+"/", data);
		}
	},
    detail: {
		url: `${config.API_URL}/entity/`,
		name: "查询单位详情",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},
    list: {
		url: `${config.API_URL}/entity/`,
		name: "查询单位列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    update: {
		url: `${config.API_URL}/entity/`,
		name: "修改单位",
		patch: async function(id,data){
			return await http.patch(this.url+id+"/", data);
		}
	},
    select:{
        url: `${config.API_URL}/searchEntity/`,
		name: "下拉查询单位列表",
        get: async function (data) {
			if(data!=null&&data!=""){
				return await http.get(this.url+data+"/");
			}else{
				return await http.get(this.url);
			}
		}
    },
	uplogo:{
        url: `${config.API_URL}/updateEntityLogo`,
		name: "下拉查询单位列表",
		post: async function(data){
			return await http.post(this.url, data);
		}
    },
	children:{
		url: `${config.API_URL}/lowLeveEntity/`,
		name: "查询单位列表",
		get: async function(id){
			return await http.get(this.url+id+'/');
		}
	},
}
