import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dataV from '@jiaminghi/data-view'
import api from './api'
import config from "./config"
import tool from './utils/tools'
import time from './utils/time'
// 语言切换
import i18n from './language'
// import VueI18n from 'vue-i18n'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/icons/iconfont/iconfont.css"

// import * as Three from 'three'
import Icon from 'vue2-svg-icon/Icon'

import '@/assets/icons/iconfont/iconfont'
import * as echarts from 'echarts'
import http from "./utils/request";

import '@/assets/icons/index';
// import 'default-passive-events'

// 处理Chrome浏览器报错
import VueClipboard from 'vue-clipboard2'
import 'default-passive-events'

// 公共样式
import '@/theme/app.scss'

// 黑暗模式
import '@/theme/theme-drak.scss'


// Vue.use(VueI18n)
Vue.use(VueClipboard)
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(dataV)
Vue.prototype.$HTTP = http;
Vue.prototype.$API = api;
Vue.prototype.$TOOL = tool;
Vue.prototype.$CONFIG = config;
Vue.prototype.$TIME =  time
Vue.component('iconTest',Icon);
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
